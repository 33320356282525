import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./stopForm.data.form";
import InputTextarea from "../../../utils/InputTextarea";
import InputSelect from "../../../utils/InputSelect";
import InputDate from "../../../utils/InputDate";
import {
  CATEGORIES,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
  CLEAN_CAUSES,
  CHANGES_CAUSES,
  OTHER_CAUSES,
  MACHINES,
  CAUSE_OTHER_FAULT,
  CAUSE_OTHER_ENT,
} from "../../../../constants/index";

const StopForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction, play, custom, toEdit, initialValues } =
    props;
  const [causes, setCauses] = useState([]);
  const [causeSelected, setCauseSelected] = useState(null);

  useEffect(() => {
    if (toEdit && initialValues.category) {
      let causesSelected;
      switch (initialValues.category) {
        case CATEGORY_CLEAN.code:
          causesSelected = CLEAN_CAUSES;
          break;
        case CATEGORY_CHANGES.code:
          causesSelected = CHANGES_CAUSES;
          break;
        case CATEGORY_OTHERS.code:
          causesSelected = OTHER_CAUSES;
          break;

        default:
          break;
      }
      setCauseSelected(Number(initialValues.cause));
      setCauses(causesSelected);
    }
  }, [toEdit, initialValues]);

  const onChangeCategory = useCallback(async (value) => {
    let causesSelected;
    switch (value) {
      case CATEGORY_CLEAN.code:
        causesSelected = CLEAN_CAUSES;
        break;
      case CATEGORY_CHANGES.code:
        causesSelected = CHANGES_CAUSES;
        break;
      case CATEGORY_OTHERS.code:
        causesSelected = OTHER_CAUSES;
        break;

      default:
        break;
    }
    props.setFieldValue("cause", "", true);
    props.setFieldValue("machine", "", true);
    setCauseSelected(null);
    setCauses(causesSelected);
  }, []);

  const onChangeCause = useCallback(async (value) => {
    setCauseSelected(value);
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <h3 className="title">
            {play
              ? t(`forms.stopHistory.dialog.play.title`)
              : t(`forms.stopHistory.dialog.pause.title`)}
          </h3>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11 marg-btm-1rem">
          <p>
            {play
              ? t(`forms.stopHistory.dialog.play.text`)
              : t(`forms.stopHistory.dialog.pause.text`)}
          </p>
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="col-12 col-md-11">
          <form onSubmit={handleSubmit} autoComplete="off">
            {custom && (
              <div className="row">
                <div className="col-12 col-md-6 mt-2">
                  <Field
                    id="initDate"
                    labelField={t("forms.stopHistory.labels.dateInit")}
                    className="form-control"
                    name="initDate"
                    component={InputDate}
                    language="es"
                    dateFormat="dd/MM/yyyy HH:mm"
                    mandatory
                    showTimeSelect
                  />
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <Field
                    id="endDate"
                    labelField={t("forms.stopHistory.labels.dateEnd")}
                    className="form-control"
                    name="endDate"
                    component={InputDate}
                    language="es"
                    dateFormat="dd/MM/yyyy HH:mm"
                    mandatory
                    showTimeSelect
                  />
                </div>
              </div>
            )}
            {!play && (
              <>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="category"
                      name="category"
                      labelField={t("forms.stopHistory.labels.category")}
                      // className="form-control"
                      component={InputSelect}
                      placeholder=""
                      optionLabel="label"
                      optionValue="code"
                      options={CATEGORIES.map((item) => ({
                        ...item,
                        label: t(item.label),
                      }))}
                      onChangeValue={onChangeCategory}
                      classNamePrefix="dark"
                      mandatory
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <Field
                      id="cause"
                      name="cause"
                      labelField={t("forms.stopHistory.labels.cause")}
                      // className="form-control"
                      component={InputSelect}
                      placeholder=""
                      optionLabel="label"
                      optionValue="code"
                      options={causes.map((item) => ({
                        ...item,
                        label: t(item.label),
                      }))}
                      addOptionEmpty
                      onChangeValue={onChangeCause}
                      classNamePrefix="dark"
                      mandatory
                    />
                  </div>
                </div>
                {(causeSelected === CAUSE_OTHER_FAULT.code ||
                  causeSelected === CAUSE_OTHER_ENT.code) && (
                  <div className="row">
                    <div className="col-12 mt-2">
                      <Field
                        id="machine"
                        name="machine"
                        labelField={t("forms.stopHistory.labels.machine")}
                        // className="form-control"
                        component={InputSelect}
                        placeholder=""
                        optionLabel="label"
                        optionValue="code"
                        options={MACHINES.map((item) => ({
                          ...item,
                          label: t(item.label),
                        }))}
                        addOptionEmpty
                        classNamePrefix="dark"
                        mandatory
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="row">
              <div className="col-12 mt-2">
                <Field
                  id="comment"
                  className="form-control"
                  labelField={t("forms.stopHistory.labels.comment")}
                  name="comment"
                  component={InputTextarea}
                  fieldType="text"
                  placeholder=""
                  mandatory
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center mt-5 mb-5">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {play
                    ? t(`forms.stopHistory.dialog.play.buttonNo`)
                    : t(`forms.stopHistory.dialog.pause.buttonNo`)}
                </button>
                <button type="submit" className="btn btn-lg btn-primary">
                  {play
                    ? t(`forms.stopHistory.dialog.play.buttonOk`)
                    : custom
                    ? t(`forms.stopHistory.save`)
                    : t(`forms.stopHistory.dialog.pause.buttonOk`)}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

StopForm.propTypes = {
  handleSubmit: PropTypes.func,
  cancelAction: PropTypes.func,
};

const StopFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "StopForm",
})(StopForm);

export default StopFormF;
