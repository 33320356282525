export const PAGE_SIZE_10 = 10;
export const CLEAN_AUTH = "CLEAN_AUTH";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_PROFILE = "GET_PROFILE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_TURN_VIEW_TYPE = "SET_TURN_VIEW_TYPE";
export const CLEAN_NAV = "CLEAN_NAV";
export const PREREGISTER_EMPLOYEE = "PREREGISTER_EMPLOYEE";
export const PRODUCTION_PROCESS_PARTIDORA = 1;

export const ROLE_TECHNICAL = "technical";
export const ROLE_USER_RRHH = "userRRHH";
export const ROLE_ADMIN_RRHH = "adminRRHH";
export const ROLE_EXTERNAL = "external";
export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const TURN_VIEW_CALENDAR = "calendar";
export const TURN_VIEW_LIST = "list";

export const LOCATION_TYPE_ENTRY = 0;
export const LOCATION_TYPE_OUTPUT = 1;
export const LOCATION_TYPE_CHANGE = 2;

export const OUT_PRODUCTION_TYPE = 0;
export const REPROCESS_TYPE = 1;
export const OUT_CALIBRE = ["5", "6", "7", "8", "9"];

export const NORMAL_ENTRY_CODE = 0;
export const REPROCESS_ENTRY_CODE = 1;

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_EN = {
  code: "en",
  label: "language.languages.en",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT, LANGUAGE_EN];

export const VARIETY_GUARA = {
  code: "G",
  label: "forms.productType.guara",
};

export const VARIETY_DEFAULT = {
  code: "",
  label: "forms.productType.default",
};

export const VARIETIES = [VARIETY_DEFAULT, VARIETY_GUARA];

export const STATUS_OPEN = {
  code: "OPEN",
  label: "status.open",
};

export const STATUS_CLOSE = {
  code: "CLOSE",
  label: "status.close",
};

export const STATUS_BLOCKED = {
  code: "BLOCKED",
  label: "status.blocked",
};

export const STATUS = [STATUS_OPEN, STATUS_CLOSE, STATUS_BLOCKED];

export const TYPE_PART = {
  code: 0,
  label: "type.partidora",
};

export const TYPE_REPRO = {
  code: 1,
  label: "type.repro",
};

export const ORDER_TYPE = [TYPE_PART, TYPE_REPRO];

export const TYPE_CASCARA = {
  code: 1,
  label: "almondType.cascara",
};

export const TYPE_GRANO = {
  code: 2,
  label: "almondType.grano",
};

export const TYPE_TROZO = {
  code: 3,
  label: "almondType.trozo",
};

export const TYPE_PODRIDO = {
  code: 4,
  label: "almondType.podrido",
};

export const TYPE_RECHAZO = {
  code: 5,
  label: "almondType.rechazo",
};

export const ALMOND_TYPE = [
  TYPE_CASCARA,
  TYPE_GRANO,
  TYPE_TROZO,
  TYPE_PODRIDO,
  TYPE_RECHAZO,
];

export const ORIGEN01 = {
  code: "SILO MOJADO 1",
  label: "origen.silo1",
};

export const ORIGEN02 = {
  code: "SILO MOJADO 2",
  label: "origen.silo2",
};

export const ORIGEN03 = {
  code: "SILO MOJADO 3",
  label: "origen.silo3",
};

export const ORIGEN04 = {
  code: "SILO MOJADO 4",
  label: "origen.silo4",
};

export const ORIGEN05 = {
  code: "SILO MOJADO 5",
  label: "origen.silo5",
};

export const ORIGEN06 = {
  code: "SILO MOJADO 6",
  label: "origen.silo6",
};

export const ORIGEN07 = {
  code: "SILO MOJADO 7",
  label: "origen.silo7",
};

export const ORIGEN08 = {
  code: "SILO MOJADO 8",
  label: "origen.silo8",
};

export const ORIGEN09 = {
  code: "SILO MOJADO 9",
  label: "origen.silo9",
};

export const ORIGEN10 = {
  code: "ARMAZEM DA CASCA",
  label: "origen.silo10",
};

export const ORIGINS = [
  ORIGEN01,
  ORIGEN02,
  ORIGEN03,
  ORIGEN04,
  ORIGEN05,
  ORIGEN06,
  ORIGEN07,
  ORIGEN08,
  ORIGEN09,
  ORIGEN10,
];

export const DESTINY01 = {
  code: "PROPIETARIO",
  label: "destiny.prop",
};

export const DESTINY02 = {
  code: "CALIBRADO",
  label: "destiny.cal",
};

export const DESTINY03 = {
  code: "A CONTENEDORES SIN SECAR",
  label: "destiny.cont",
};

export const DESTINIES = [DESTINY01, DESTINY02, DESTINY03];

export const MOVE_TYPE_USED = {
  code: 0,
  label: "forms.moveFlow.moveFlowType.used",
};

export const MOVE_TYPE_PRODUCTION = {
  code: 1,
  label: "forms.moveFlow.moveFlowType.production",
};

export const MOVE_TYPES = [MOVE_TYPE_USED, MOVE_TYPE_PRODUCTION];

export const ITEM_TYPE_CASCARA = {
  code: 1,
  label: "forms.moveFlow.itemType.cas",
};

export const ITEM_TYPE_GRANO = {
  code: 2,
  label: "forms.moveFlow.itemType.gra",
};

export const ITEM_TYPE_TROZO = {
  code: 3,
  label: "forms.moveFlow.itemType.tro",
};

export const ITEM_TYPE_PODRIDO = {
  code: 4,
  label: "forms.moveFlow.itemType.pod",
};

export const ITEM_TYPES = [
  ITEM_TYPE_CASCARA,
  ITEM_TYPE_GRANO,
  ITEM_TYPE_TROZO,
  ITEM_TYPE_PODRIDO,
];

export const SI_PARTIENDO = {
  code: true,
  label: "forms.moveFlow.partiendo.yes",
};

export const NO_PARTIENDO = {
  code: false,
  label: "forms.moveFlow.partiendo.no",
};

export const PARTIENDO = [SI_PARTIENDO, NO_PARTIENDO];

export const AUX_BB = {
  code: 1,
  label: "forms.auxItems.type.bb",
};

export const AUX_TAG = {
  code: 2,
  label: "forms.auxItems.type.tag",
};

export const AUX_TYPE = [AUX_BB, AUX_TAG];

export const CATEGORY_HOLIDAY = {
  code: 1,
  label: "category.holiday",
};
export const CATEGORY_CLEAN = {
  code: 2,
  label: "category.clean",
};
export const CATEGORY_CHANGES = {
  code: 3,
  label: "category.changes",
};
export const CATEGORY_OTHERS = {
  code: 4,
  label: "category.others",
};
export const CATEGORIES = [
  // CATEGORY_HOLIDAY,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
];

export const LIST_CATEGORIES = [
  CATEGORY_HOLIDAY,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
];

export const CALENDAR_LANG = {
  es: {
    week: "Semana",
    work_week: "Semana de trabajo",
    day: "Día",
    month: "Mes",
    previous: "Atrás",
    next: "Después",
    today: "Hoy",
    agenda: "El Diario",
    showMore: (total) => `+${total} más`,
  },
  pt: {
    week: "Semana",
    work_week: "Semana de trabalho",
    day: "Dia",
    month: "Malha",
    previous: "Detrás de",
    next: "Depois",
    today: "Hoy",
    agenda: "El Diario",
    showMore: (total) => `+${total} mais`,
  },
};

export const EVENT_STYLE = {
  100: {
    background: "#203b34",
    color: "#ffffff",
    border: "1px solid #ccc",
  },
  101: {
    background: "#203b3494",
    color: "#ffffff",
    border: "1px solid #ccc",
  },
  102: {
    background: "#297360",
    color: "#ffffff",
    border: "1px solid #ccc",
  },
  1: {
    background: "#F0843A",
    color: "#fff",
    border: "1px solid #F0843A",
  },
  2: {
    //Previstas
    background: "#93599B",
    color: "#ffffff",
    border: "1px solid #93599B",
  },
  3: {
    //Cambio de linea
    background: "#fab442",
    color: "#ffffff",
    border: "1px solid #fab442",
  },
  4: {
    //Averias
    background: "#F0643A",
    color: "#ffffff",
    border: "1px solid #F0643A",
  },
};

export const CAUSE_CLEAN_INIT = {
  code: 1,
  label: "cause.init",
};
export const CAUSE_CLEAN_PREVENT = {
  code: 2,
  label: "cause.prevent",
};
export const CAUSE_CLEAN_CLEAN = {
  code: 3,
  label: "cause.clean",
};
export const CAUSE_CLEAN_CLASS = {
  code: 4,
  label: "cause.class",
};
export const CAUSE_CLEAN_CANT = {
  code: 5,
  label: "cause.cant",
};
export const CAUSE_CLEAN_TEST = {
  code: 6,
  label: "cause.test",
};

export const CAUSE_CHANGE_BATCH = {
  code: 7,
  label: "cause.batch",
};
export const CAUSE_CHANGE_CHANGE = {
  code: 8,
  label: "cause.change",
};

export const CAUSE_OTHER_FAULT = {
  code: 9,
  label: "cause.fault",
};
export const CAUSE_OTHER_PROCESS = {
  code: 10,
  label: "cause.process",
};
export const CAUSE_OTHER_EVENT = {
  code: 11,
  label: "cause.event",
};
export const CAUSE_OTHER_EE = {
  code: 12,
  label: "cause.ee",
};
export const CAUSE_OTHER_WATER = {
  code: 13,
  label: "cause.water",
};
export const CAUSE_OTHER_MAT = {
  code: 14,
  label: "cause.mat",
};
export const CAUSE_OTHER_LOG = {
  code: 15,
  label: "cause.log",
};
export const CAUSE_OTHER_PER = {
  code: 16,
  label: "cause.per",
};
export const CAUSE_OTHER_ENT = {
  code: 17,
  label: "cause.ent",
};
export const CAUSE_OTHER_HUM = {
  code: 18,
  label: "cause.hum",
};

export const CLEAN_CAUSES = [
  CAUSE_CLEAN_INIT,
  CAUSE_CLEAN_PREVENT,
  CAUSE_CLEAN_CLEAN,
  CAUSE_CLEAN_CLASS,
  CAUSE_CLEAN_CANT,
  CAUSE_CLEAN_TEST,
];

export const CHANGES_CAUSES = [CAUSE_CHANGE_BATCH, CAUSE_CHANGE_CHANGE];

export const OTHER_CAUSES = [
  CAUSE_OTHER_FAULT,
  CAUSE_OTHER_HUM,
  CAUSE_OTHER_ENT,
  CAUSE_OTHER_PROCESS,
  CAUSE_OTHER_EVENT,
  CAUSE_OTHER_EE,
  CAUSE_OTHER_WATER,
  CAUSE_OTHER_MAT,
  CAUSE_OTHER_LOG,
  CAUSE_OTHER_PER,
];

export const ALL_CAUSES = [
  CAUSE_CLEAN_INIT,
  CAUSE_CLEAN_PREVENT,
  CAUSE_CLEAN_CLEAN,
  CAUSE_CLEAN_CLASS,
  CAUSE_CLEAN_CANT,
  CAUSE_CLEAN_TEST,
  CAUSE_CHANGE_BATCH,
  CAUSE_CHANGE_CHANGE,
  CAUSE_OTHER_FAULT,
  CAUSE_OTHER_HUM,
  CAUSE_OTHER_ENT,
  CAUSE_OTHER_PROCESS,
  CAUSE_OTHER_EVENT,
  CAUSE_OTHER_EE,
  CAUSE_OTHER_WATER,
  CAUSE_OTHER_MAT,
  CAUSE_OTHER_LOG,
  CAUSE_OTHER_PER,
];

export const MACHINE_LAV = {
  code: 1,
  label: "machine.lav",
};
export const MACHINE_SILO = {
  code: 2,
  label: "machine.silo",
};
export const MACHINE_VAR = {
  code: 3,
  label: "machine.var",
};
export const MACHINE_PAR = {
  code: 4,
  label: "machine.par",
};
export const MACHINE_SEP = {
  code: 5,
  label: "machine.sep",
};
export const MACHINE_SMART = {
  code: 6,
  label: "machine.smart",
};
export const MACHINE_TOM = {
  code: 7,
  label: "machine.tom",
};
export const MACHINE_SEC = {
  code: 8,
  label: "machine.sec",
};
export const MACHINE_ENF = {
  code: 9,
  label: "machine.enf",
};
export const MACHINE_TAIHO = {
  code: 10,
  label: "machine.taiho",
};
export const MACHINE_CAL = {
  code: 11,
  label: "machine.cal",
};
export const MACHINE_BAS = {
  code: 12,
  label: "machine.bas",
};
export const MACHINE_TRANS = {
  code: 13,
  label: "machine.trans",
};

export const MACHINES = [
  MACHINE_LAV,
  MACHINE_SILO,
  MACHINE_VAR,
  MACHINE_PAR,
  MACHINE_SEP,
  MACHINE_SMART,
  MACHINE_TOM,
  MACHINE_SEC,
  MACHINE_ENF,
  MACHINE_TAIHO,
  MACHINE_CAL,
  MACHINE_BAS,
  MACHINE_TRANS,
];

export const HOUR_TYPE_8 = {
  code: 8,
  label: "forms.turnHistory.hourType.8",
};

export const HOUR_TYPE_12 = {
  code: 12,
  label: "forms.turnHistory.hourType.12",
};

export const HOURS_TYPES = [HOUR_TYPE_8, HOUR_TYPE_12];

export const TURN_STATUS_CLOSE = {
  code: 0,
  label: "forms.turnHistory.status.0",
};
export const TURN_STATUS_INIT = {
  code: 1,
  label: "forms.turnHistory.status.1",
};
export const TURN_STATUS_FIXED = {
  code: 2,
  label: "forms.turnHistory.status.2",
};

export const TURN_STATUS = [
  TURN_STATUS_CLOSE,
  TURN_STATUS_INIT,
  TURN_STATUS_FIXED,
];
