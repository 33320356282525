import * as Yup from "yup";

export const dataModel = {
  comment: "",
  category: "",
  cause: "",
  machine: "",
  initDate: "",
  endDate: "",
};

export const validationDataModel = () => {
  return Yup.object().shape({
    category: Yup.string().required("forms.validations.required"),
    cause: Yup.string().required("forms.validations.required"),
    machine: Yup.string().optional("forms.validations.required"),
    comment: Yup.string().optional("forms.validations.required"),
    initDate: Yup.date().optional("forms.validations.required"),
    endDate: Yup.date().optional("forms.validations.required"),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
